<template>
  <div class="store-payment">
    <div class="tripple-box-container">
      <div id="box_1" class="payment-box shadow p-3 mb-5 bg-body">
        <div style="display: flex;
                 flex-direction: column;
                 justify-content: space-between;">
          <div style="margin-bottom: 0.5rem">
            1.รายละเอียดโปรโมชั่น
          </div>
          <div style="position: relative">
            <img v-if="card_data" class="promo-preview" :src="card_data.image" />
            <img v-else class="promo-preview" src="@/assets/Matchday_Logo.jpg" />
            <img v-if="card_data" class="promo-preview-logo" :src="card_data.provider.logo" />
          </div>
          <div style="padding: 1rem 0rem">
            <div class="promo-countdown">
              <div style="font-size: 1rem" v-if="card_data">
                {{ card_data.provider.fullname }}
              </div>
              <div class="promo-countdown" style="align-item: center; width:25%" hidden>
                <img style="height: 1rem" src="@/assets/Store_icon_flash.png" />
                <div>สิ้นสุดใน</div>
                <div>0{{ countdown_number }}</div>
              </div>
            </div>
            <span style="font-weight: 500" v-if="card_data">{{
            card_data.name
            }}</span>
          </div>
          <div>
            <div class="promo-info-header">รายละเอียด</div>
            <ol v-if="card_data ? card_data.detail : false" class="promo-info-detail">
              <div v-for="detail in card_data.detail" :key="detail.key">
                <li>{{ detail }}</li>
              </div>
            </ol>
            <ol v-else class="promo-info-detail">
              <li v-if="card_data">
                ระยะเวลาโปรโมชัน {{ get_expire(card_data.serving_days) }}
              </li>
              <li>
                ลูกค้าสามารถใช้คูปองส่วนลด และแสดงให้พนังงานที่สนามได้ทันที
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div id="box_2" class="payment-box shadow p-3 mb-5 bg-body">
        <div>
          <div>
            2.รายละเอียดของคุณ
          </div>
          <span style="font-size: 0.75rem; color: #A7ABBE;">กรุณากรอกรายละเอียดให้ถูกต้องเพื่อชำระเงิน</span>
        </div>
        <div style="display:flex;flex-direction: column;justify-content: space-evenly;height:100%">
          <div class="store-input-container">
            <div style="margin-top: 0;" class="store-input-row">
              <div class="store-input-placeholder">
                <label for="name">ชื่อ</label>
                <label style="color: red" class="error" v-if="errors.includes('name')" for="name">กรุณากรอกชื่อ</label>
              </div>
              <input :disabled="promo_stage == 3" class="store-input" id="name" v-model="name" placeholder="ระบุชื่อ"
                required type="text" />
            </div>
            <div class="store-input-row">
              <div class="store-input-placeholder">
                <label for="surname">นามสกุล</label>
                <label style="color: red" class="error" v-if="errors.includes('surname')"
                  for="surname">กรุณากรอกนามสกุล</label>
              </div>
              <input :disabled="promo_stage == 3" class="store-input" id="email" v-model="surname"
                placeholder="ระบุนามสกุล" required type="text" />
            </div>
            <div class="store-input-row">
              <div class="store-input-placeholder">
                <label for="email">อีเมล</label>
                <label style="color: red" class="error" v-if="errors.includes('email')"
                  for="name">กรุณากรอกอีเมล</label>
              </div>
              <input :disabled="promo_stage == 3" class="store-input" id="email" v-model="email" placeholder="ระบุอีเมล"
                required type="email" />
            </div>
            <div class="store-input-row">
              <div class="store-input-placeholder">
                <label for="phone">หมายเลขโทรศัพท์</label>
                <label style="color: red" class="error" v-if="errors.includes('phone')"
                  for="name">กรุณากรอกเบอร์โทรศัพท์</label>
              </div>
              <input :disabled="promo_stage == 3" class="store-input" id="phone" v-model="phone"
                placeholder="ระบุหมายเลขโทรศัพท์" required type="text" />
            </div>
          </div>

          <div style="display: flex; flex-direction: column">
            <div style="display: flex;justify-content: space-around;align-item: center">
              <input id="accept_news" type="checkbox" style="width: 4vw;height: 1.5rem" />
              <label style="font-size: 1rem;" for="accept_news">ฉันต้องการรับอีเมลเกี่ยวกับข้อเสนอสินค้าและบริการจาก
                Matchday
                <a class="error">(แนะนำ)</a></label>
            </div>
            <div style="display: flex;justify-content: space-around;align-item: center">
              <input v-model="t_c" id="accept_tnc" type="checkbox" style="width: 4vw;height: 1.5rem" required />
              <label style="font-size: 1rem;" for="accept_tnc">
                ฉันยอมรับข้อตกลง<a class="promo-link" href="/terms" target="_blank">เงื่อนไขการใช้งาน</a>และ<a
                  class="promo-link" href="/privacy-policy" target="_blank">นโยบายความเป็นส่วนตัว</a>
                <a class="error">{{
                errors.includes("t_c") ? " (จำเป็น)" : ""
                }}</a>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="box_3" class="payment-box shadow p-3 mb-5 bg-body">
        <div>
          3.วิธีการใช้คูปอง !
          <div class="instruction-content">
            <div class="ins-wrap">
              <img style="height: 100%; margin: 1rem" src="@/assets/Store_icon_Email.png" />
              <div class="ins-text">
                <span>แจ้งเตือนรหัสคูปองส่วนลด</span>
                <span style="font-size: 1rem; color: #A7ABBE; font-weight: 300">หลังจากชำระเงินเสร็จลูกค้าจะได้ email
                  แจ้งเตือนพร้อม Code
                  ส่วนลด</span>
              </div>
            </div>
            <div class="ins-wrap">
              <img style="height: 100%; margin: 1rem" src="@/assets/Store_icon_Coupon.png" />
              <div class="ins-text">
                <span>แสดงคูปองส่วนลดให้กับพนักงาน</span>
                <span style="font-size: 1rem; color: #A7ABBE; font-weight: 300">ลูกค้าสามารถใช้คูปองส่วนลด
                  และแสดงให้พนังงานที่สนามได้ทันที
                  หรือสามารถใช้ส่วนลดในการจองผ่านแอปพลิเคชัน Matchday</span>
              </div>
            </div>
          </div>
        </div>
        <img style="margin: 2rem 0;width: 100%" src="@/assets/Store_Box_dot.png" />
        <div class="promo-quantity">
          <span>จำนวนคูปอง</span>
          <div class="buy-controllers">
            <button @click="rem_vol()" type="button" class="btn btn-light buy-con" :disabled="buy_vol == min_amount">
              -
            </button>
            <span>{{ buy_vol }}</span>
            <button @click="add_vol()" type="button" class="btn btn-light buy-con">
              +
            </button>
          </div>
        </div>
        <div class="promo-price">
          <div>
            <div class="upper-price">
              <span>ราคาเต็ม</span>
              <span v-if="card_data">{{full_price}}
                ฿</span>
            </div>
            <div class="store-input-placeholder" style="align-items: center">
              <div style="position: relative">
                <input class="store-input" v-model="voucher_promo" placeholder="กรอกโค้ด (ถ้ามี)" type="text"
                  style="font-size: 1rem;height:fit-content;margin-right: 1rem;padding: 0.375rem 0.75rem;border-radius: 5px"
                  :disabled="promo_stage == 3" />

                <a @click="reset_promo()" :style="promo_stage == 3 ? '' : 'display: none'" class="cancel-promo">
                  <i class="link remove_promo fas fa-trash-alt"></i>
                </a>
              </div>

              <button v-if="promo_stage == 2" @click="redeem_promo()" type="button"
                style="width: 10rem;background-color: #f50201;border-color: #f50201;box-shadow:0 0.5rem 1rem rgba(0,0,0,0.15)"
                class="btn btn-success" :disabled="promo_stage == 3">
                ใช้งาน
              </button>

              <button v-else-if="promo_stage == 3" type="button"
                style="width: 10rem;border-radius: 5px;background-color: #009881" class="btn btn-success"
                :disabled="promo_stage == 3">
                กำลังใช้งาน
              </button>

              <button v-else @click="check_promo(voucher_promo)" type="button"
                style="width: 10rem;border-radius: 5px;background-color: #009881;box-shadow:0 0.25rem 0.5rem rgba(0,0,0,0.15)"
                class="btn btn-success" :disabled="promo_stage == 3">
                ตรวจสอบ
              </button>
            </div>
            <div v-if="promo_stage > 0">
              <div v-if="promo_code && promo_stage == 3" class="upper-price">
                <div>
                  <img src="@/assets/discount_tag.png" />
                  <span style="padding-left: 0.25rem">ส่วนลดจาก {{ promo_code.name }}</span>
                </div>
                <span style="color: red">- {{ promotion_calculator() }} ฿</span>
              </div>
              <div v-else-if="promo_stage <= 2 && promo_code" class="upper-price">
                <span style="font-size: 1rem; color: #A7ABBE">ส่วนลด
                  <span style="color: rgb(0,152,129)">{{
                  promo_code.name
                  }}</span>
                  {{
                  promo_code.in_condition
                  ? `พร้อมใช้งาน! - มอบส่วนลด ${promo_code.params.value}${
                  promo_code.params.percent ? "%" : " ฿"
                  } (ลดสูงสุด ${promo_code.params.max} ฿)`
                  : "ไม่ถูกต้อง หรือ ไม่อยู่ในเงื่อนไขการใช้งาน"
                  }}
                </span>
              </div>
              <div v-else class="upper-price">
                <span style="font-size: 1rem; color: red">ไม่พบคูปอง !</span>
              </div>
            </div>
            <div v-if="card_data ? card_data.price_original > 0 : false" class="upper-price">
              <div>
                <img src="@/assets/discount_tag.png" />
                <span style="padding-left: 0.25rem">ส่วนลดจาก Matchday</span>
              </div>
              <span style="color: red">-
                {{ (full_price - net_price)}}
                ฿</span>
            </div>
          </div>
          <div class="upper-price seperator">
            <div class="lower-price">
              <span>ราคาสุทธิ</span>
              <span style="font-size: 1rem; color: #A7ABBE">รวมภาษี และค่าใช้จ่ายอื่นๆ</span>
            </div>
            <span style="color:#009881; font-size: 28px" v-if="card_data">{{ net_price - promotion_calculator() }}
              ฿</span>
          </div>
        </div>
        <div class="instruction-footer">
          <button style="background-color: #f50201;border-color: #f50201; font-size: 1rem;" @click="buy_bundle()"
            type="button" class="btn btn-success" :disabled="on_loading">
            ชำระเงิน
          </button>
        </div>
      </div>
    </div>
    <StorePayso ref="paymentForm" :return_url="'https://matchday.co.th/payment-confirmation/'" />
  </div>
</template>

<script>
import moment from "moment";
import StorePayso from "./store_payso";
import StoreService from "../resources/store_service";

import "moment/locale/th";
import Swal from 'sweetalert2';
export default {
  name: "StorePayment",
  components: {
    StorePayso
  },
  data() {
    return {
      errors: [],
      card_data: null,
      countdown_number: 0,
      countdown_default: 15,
      countdown_timer: null,
      voucher_promo: null,
      on_loading: false,
      buy_vol: 1,
      min_amount: 1,
      promo_stage: 0,
      promo_code: null,
      name: null,
      surname: null,
      phone: null,
      email: null,
      t_c: false,
      found_promo: null
    };
  },
  computed: {
    full_price() {
      return this.card_data.price_per_min_amount? 
      this.card_data.price_original * (this.buy_vol/this.min_amount):
      (this.card_data.price_original > 0? 
        this.card_data.price_original * this.buy_vol:
        this.card_data.price * this.buy_vol)
    },
    net_price() {
      return this.card_data.price_per_min_amount? 
        this.card_data.price_per_min_amount * (this.buy_vol/this.min_amount):
        this.card_data.price * this.buy_vol
    }
  },
  methods: {
    reset_promo() {
      this.promo_stage = 0;
      this.promo_code = null;
    },
    async check_promo(input) {
      this.check_input();
      if (input) {
        var code = input.toUpperCase();
        if (this.errors.length == 0) {
          const service = new StoreService();
          const res = await service.check_promotion({
            email: this.email,
            phone_number: this.phone,
            code
          });
          if (res) {
            this.promo_code = res;
            this.promo_stage = res.in_condition ? 2 : 1;
          } else {
            this.promo_stage = 1;
          }
        }
      }
    },
    redeem_promo() {
      this.promo_stage = 3;
    },
    promotion_calculator() {
      if (this.promo_code && this.promo_stage == 3) {
        const { percent, value, max } = this.promo_code.params;
        var total_value = this.card_data.price * this.buy_vol;
        var total_reduce = percent
          ? (total_value * value) / 100
          : value * this.buy_vol;
        return total_reduce <= max ? total_reduce : max;
      } else {
        return 0;
      }
    },
    countdown_timer_init() {
      var duration = moment.duration(this.countdown_default, "minutes");
      this.countdown_timer = setInterval(() => {
        duration = moment.duration(duration - 1000, "milliseconds");
        this.countdown_number = `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
      }, 1000);
    },
    add_vol() {
      this.buy_vol = this.buy_vol + (1 * this.min_amount);
    },
    rem_vol() {
      if (this.buy_vol > this.min_amount) {
        this.buy_vol = this.buy_vol - (1 * this.min_amount);
      }
    },
    check_input() {
      if (!this.name) {
        this.errors.push("name");
      } else {
        this.errors.splice(this.errors.indexOf("name"), 1);
      }
      if (!this.surname) {
        this.errors.push("surname");
      } else {
        this.errors.splice(this.errors.indexOf("surname"), 1);
      }
      if (!this.email || !this.email.includes("@")) {
        this.errors.push("email");
      } else {
        this.errors.splice(this.errors.indexOf("email"), 1);
      }
      if (!this.phone) {
        this.errors.push("phone");
      } else {
        this.errors.splice(this.errors.indexOf("phone"), 1);
      }
      if (!this.t_c) {
        this.errors.push("t_c");
      } else {
        this.errors.splice(this.errors.indexOf("t_c"), 1);
      }
    },
    async get_user(userObj) {
      const service = new StoreService();
      return service.get_user_for_payment(userObj);
    },
    async get_bundle_refno() {
      const service = new StoreService();
      return service.get_bundle_refno();
    },
    async buy_bundle() {
      const service = new StoreService();

      this.check_input();
      if (this.errors.length == 0) {
        this.on_loading = true;
        const userObj = {
          fullname: this.name,
          phone_number: this.phone,
          email: this.email
        };
        const allowed_max_per_user = await service.check_bundle_limit(userObj, this.card_data.id, this.buy_vol)
        if (allowed_max_per_user) {
          const user = await this.get_user(userObj);
          const { refno } = await this.get_bundle_refno();
          this.$refs.paymentForm.buy_bundle(
            {
              refno: refno,
              amount: this.buy_vol,
              bundle: this.card_data,
              total_reduction: (this.promo_stage == 3)
                ? this.promotion_calculator()
                : 0,
              net_amount: this.net_price
              // promotion_id: (this.promo_stage == 3) ? (this.promo_stage == 3) : null
            },
            { email: userObj.email, user_id: user.user.id }
          );
        } else {
          this.on_loading = false
          Swal.fire({
            icon: 'error',
            title: 'ขออภัย',
            text: 'โปรโมชั่นนี้ของคุณถึงจำนวนจำกัดแล้ว'
          })
        }
      }
    },
    set_card_data(bundle) {
      if (bundle.detail) {
        bundle.detail = bundle.detail.split("-");
      }
      this.card_data = bundle;
      this.buy_vol = bundle.min_amount
      this.min_amount = bundle.min_amount
    },
    get_expire(days) {
      if (days) {
        const start = moment()
          .locale("th")
          .format("DD MMMM YYYY");
        const end = moment()
          .locale("th")
          .add(30, "days")
          .format("DD MMMM YYYY");
        return `ตั้งแต่วันที่ ${start} ถึง ${end}`;
      } else {
        return "ไม่จำกัด";
      }
    }
  },
  watch: {
    phone: function (newVal) {
      this.phone = newVal.replace(/[a-z]|[A-Z]|\W/g, "");
    }
  },
  mounted() {
    this.countdown_timer_init();
  },
  async mounted() {
    const card_data = await new StoreService().get_bundle(this.$route.params.bundle_id);
    this.set_card_data(card_data);
  }
};
</script>

<style scoped>
.cancel-promo {
  position: absolute;
  cursor: pointer;
  right: 24px;
  top: 10px;
}

.remove_promo {
  position: absolute;
}

.promo-price {
  font-size: 1rem;
  font-weight: 360;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.error {
  align-self: flex-end;
  font-weight: 300;
  font-size: 0.75rem;
}

.promo-link {
  color: #212529;
  text-decoration: underline;
}

.instruction-footer {
  padding-top: 0.5rem;
  /* border-top: 1px solid rgba(0,0,0,0.2); */
  display: flex;
  justify-content: center;
}

.instruction-content {
  font-weight: 400;
}

.ins-text {
  display: flex;
  flex-direction: column;
}

.ins-wrap {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}

/* .header-instruction {
  font-size: 1.55vw;
} */
.store-input-placeholder {
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
}

.payment-box {
  display: flex;
  flex: 1 1 0px;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 6px;
  margin: 3px;
}

.seperator {
  display: flex;
  justify-content: space-between;
  padding-top: 1vw;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.lower-price {
  display: flex;
  flex-direction: column;
}

.upper-price {
  display: flex;
  justify-content: space-between;
  margin: 0.6vw 0;
}

.buy-controllers {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buy-con {
  background-color: white;
  font-size: 1rem;
  font-weight: 500;
  height: 2.223vw;
  width: 2.223vw;
  border-radius: 5px;
  padding: 0;
  line-height: 0;
}

.promo-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 3.75vw;
  font-size: 1rem;
  background-color: #f5f6f7;
  border: 1px solid #dfe1ea;
  border-radius: 5px;
}

.promo-countdown {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
}

.promo-info-detail {
  padding-inline-start: 2%;
}

.promo-info-header {
  color: #a7abbe;
}

.promo-preview {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  /* height: 18.309vw; */
}

.promo-preview-logo {
  position: absolute;
  right: 3%;
  top: 3%;
  object-fit: cover;
  border-radius: 100%;
  width: 20%;
}

.promo-container {
  position: relative;
  height: 57.359vw;
  width: 32.574vw;
}

.promo-content {
  position: absolute;
  width: 32.574vw;
}

.store-input-row {
  margin: 1vw 0vw;
}

.store-input {
  display: flex;
  flex-direction: column;
  height: 3.959vw;
  width: 100%;
  padding: 1rem;
  border: 1px solid #dfe1ea;
  border-radius: 10px;
  font-size: 1rem;
}

.store-input>input::placeholder {
  color: #dfe1ea;
}

.tripple-box-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3%;
}

.store-payment {
  background-color: #f7f7f7;
  padding-bottom: 5%;
}

@media (max-width: 800px) {
  .tripple-box-container {
    flex-direction: column;
  }
}
</style>
