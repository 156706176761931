<template>
  <div style="display:none">
    <form
      id="inputValue"
      method="post"
      action="https://www.thaiepay.com/epaylink/payment.aspx"
    >
      <input id="refno" type="hidden" name="refno" value=" " />
      <input type="hidden" name="merchantid" value="29221002" />
      <input id="cusMail" type="hidden" name="customeremail" value=" " />
      <input id="proDetail" type="hidden" name="productdetail" value=" " />
      <input type="hidden" name="cc" value="00" />
      <input id="total" type="hidden" name="total" value=" " />
      <input type="hidden" name="returnurl" :value="on_success_url" />
      <br />
      <input
        id="pay-trigger"
        type="submit"
        name="Submit"
        value="Comfirm Order"
      />
    </form>
  </div>
</template>

<script>
import PortalService from '../resources/portal_service';

export default {
  props: {
    return_url: String
  },
  data() {
    return {
      refno: null,
      email: null,
      detail: null,
      price: null,
      on_success_url: null,
      productType: "bundle",
      trigger: false
    };
  },
  methods: {
    buy_bundle(bundleObj, user) {
      this.refno = bundleObj.refno;
      this.email = user.email;
      this.detail = `ref-Bundle-${bundleObj.bundle.id}-unit-${
        bundleObj.amount
      }-User-${user.user_id}${
        bundleObj.promotion_id ? `-Promo-${bundleObj.promotion_id}` : ``
      }`;
      this.price = bundleObj.net_amount;
      this.trigger = true;
    }
  },
  watch: {
    trigger: async function(newTrigger) {
      if (newTrigger) {
        const paymentObj = {
          email: this.email,
          detail: this.detail,
          price: this.price,
        };
        const portal_service = new PortalService()
        await portal_service.get_refno('bundle')
          .then(({ refno }) => {
            this.on_success_url = `${this.return_url}${refno}`
            localStorage.setItem("bundle_refno", `${refno}`);
            document.getElementById("refno").value = refno;
            document.getElementById("cusMail").value = paymentObj.email;
            document.getElementById("proDetail").value = paymentObj.detail;
            document.getElementById("total").value = `${paymentObj.price}`;
            setTimeout(() => {
              document.getElementById("pay-trigger").click();
            }, 100)
          })
      }
    }
  }
};
</script>
